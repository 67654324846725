import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PurpleAccentHero from "../components/PurpleAccentHero"
import { API_URL, RESOURCES_PATH } from "../utils/constants"
import axios from "axios"
import { TextField } from "@material-ui/core"
import Button from "../components/Button"
import CookiesSnackbar from "../components/CookiesSnackbar"
import {makeStyles} from "@material-ui/styles";
import DiscoverMoreBanner from "../components/DiscoverMoreBanner"

const useStyles = makeStyles({
    titleHeadingGradientWrapper: {
        width: "100%",
        height: "100%",
        background: "linear-gradient(110.11deg, #FFFFFF 38%, rgba(255, 255, 255, 0) 67.89%)",
        "@media(max-width: 959px)": {
            background: "rgba(255, 255, 255, 0) 67.89%)"
        }
    },
    titleHeadingText: {
        marginLeft: 100,
        maxWidth: 360,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",
        color: "rgba(0, 0, 0, 0.8)",
        letterSpacing: "0.25px",
        fontFamily: "Ubuntu",
        "@media (max-width: 959px)": {
            width: "100%",
            maxWidth: "100%",
            marginLeft: 0,
            padding: 24
        }
    },
    heading: {
        lineHeight: "32px",
        marginBottom: "24px !important",
        "@media (max-width: 959px)": {
            fontSize: 24,
            fontWeight: "normal"
        }
    },
    titleDescription: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "24px",
        "@media (max-width: 959px)": {
            fontSize: 14,
            fontWeight: "normal"
        }
    },
    body: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 48,
        padding: 24
    },
    bodyTitle: {
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 32,
        lineHeight: 4,
        letterSpacing: 0.25,
        marginBottom: "16px !important"
    },
    resource: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        borderRadius: 4,
        border: "1px solid rgba(101,78,163,0.12)",
        margin: 24,
        padding: 24
    },
    resourceText: {
        width: "75%",
        margin: 0,
        padding: 0
    }
})

const ResourcesPage = () => {
    const classes = useStyles()
    const [submitted, setSubmitted] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [resources, setResources] = useState([])

    const data = useStaticQuery(graphql`
        query {
            desktopHeroImage: file(relativePath: { eq: "about/about-hero-desktop.png" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 2160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            mobileHeroImage: file(relativePath: { eq: "about/about-hero-mobile.png" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    const changePassword = (event) => {
        event.preventDefault()
        setPassword(event.target.value)
    }

    const submit = async (event) => {
        event.preventDefault()
        const headers = {
            headers: {
                resourceCode: new Buffer(password).toString("base64")
            }
        }
        const response = await axios.get(`${API_URL}/GetResources`, headers)
        if (!response) {
            setPasswordError(true)
        } else if (response.status < 0) {
            setPasswordError(true)
        } else {
            setResources(response.data.data)
            setSubmitted(true)
            setPasswordError(false)
        }
    }

    const viewUrl = (url) => {
        window.open(`${RESOURCES_PATH}${url}`, "_blank")
    }

    return (
        <Layout>
            <SEO title="Partner Resources" />
            <DiscoverMoreBanner/>
            <PurpleAccentHero
                desktopImage={data.desktopHeroImage.childImageSharp.fluid}
                mobileImage={data.mobileHeroImage.childImageSharp.fluid}
                alt="Woman sitting at laptop staring into the distance"
            >
                <div className={classes.titleHeadingGradientWrapper}>
                    <section className={classes.titleHeadingText}>
                        <h1 className={classes.heading}>Partner Resources</h1>
                    </section>
                </div>
            </PurpleAccentHero>
            <section className={classes.body}>
                {!submitted || !resources ? (
                    <form noValidate autoComplete="off">
                        <TextField
                            label="Password"
                            style={{ width: "100%", marginBottom: 38 }}
                            value={password}
                            error={passwordError}
                            onChange={changePassword}
                            type="password"
                            autoComplete="current-password"
                        />
                        <Button style={{ width: 185, height: 36 }} onClick={submit}>
                            See Resources
                        </Button>
                    </form>
                ) : (
                    <div>
                        {resources.map((value, index) => (
                            <div className={classes.resource} key={`resources-${index}`}>
                                <div className={classes.resourceText}>
                                    <h3 style={{ marginTop: 0 }}>{value.title}</h3>
                                    <p>{value.description}</p>
                                </div>
                                <Button style={{ width: 120, height: 36 }} onClick={() => viewUrl(value.url)}>
                                    View
                                </Button>
                            </div>
                        ))}
                    </div>
                )}
            </section>
            <CookiesSnackbar />
        </Layout>
    )
}

export default ResourcesPage
